import React from 'react';

export function Payment() {
    return (
        <section className="section">
            <header className="section__header">
                <h2 className="section__title">Tu bedzie oplacanie</h2>
                <aside></aside>
            </header>
        </section>
    );
}
