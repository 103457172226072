import axios from 'axios';
import { setPaymentRequired } from '../user/user_repository';

export async function http(options) {
    const response = await axios(options);

    if (response.status === 402) {
        setPaymentRequired(true);
    }

    return response;
}
